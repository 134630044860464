/* @noflow */
// https://stackoverflow.com/questions/49328382/browser-detection-in-reactjs

export default function currentBrowserName() {
    // When rendering the page use an empty string with around
    // the same amount of spacing as the browser name to prevent
    // the page from reflowing too much.
    if (typeof window === 'undefined') {
        return '     ';
    }

    // Chrome 1 - 71
    const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

    // Firefox 1.0+
    const isFirefox = typeof InstallTrigger !== 'undefined';

    // Internet Explorer 6-11
    const isIE = /*@cc_on!@*/ false || !!document.documentMode; // eslint-disable-line spaced-comment, prettier/prettier

    // Edge 20+
    const isEdge = !isIE && !!window.StyleMedia;

    // Safari 3.0+ "[object HTMLElementConstructor]"
    const isSafari =
        /constructor/i.test(window.HTMLElement) ||
        ((p) => {
            return p.toString() === '[object SafariRemoteNotification]';
        })(!window.safari || (typeof safari !== 'undefined' && window.safari.pushNotification));

    if (isChrome) {
        return 'Chrome';
    }
    if (isFirefox) {
        return 'Firefox';
    }
    if (isEdge) {
        return 'Edge';
    }
    if (isSafari) {
        return 'Safari';
    }
    return 'Chrome';
}
