import React, {FC} from 'react';
import {css} from '@emotion/core';

const buttonStyles = css`
    text-decoration: none;
    font-size: 1.5rem;
    font-family: 'Raleway', sans-serif;
    border-radius: 8px;
    display: inline-block;
    font-family: inherit;
    font-weight: 700;
    letter-spacing: 0.02rem;
    line-height: 1.2;
    transition: background-color 0.3s cubic-bezier(0, 0.4, 0.74, 0.63);
    margin-right: 1rem;
    margin-bottom: 1rem;
    background-color: #a5cc01;
    border: none;
    color: #333333;
    padding: 1.5rem 5rem;
    letter-spacing: 0.1rem;
    &:hover {
        cursor: pointer;
        background-color: #98bb04;
    }

    @media (max-width: 600px) {
        width: 100%;
        padding: 1rem 0rem;
    }

    span.continue {
        width: 100%;
        height: 100%;
        font-family: 'Raleway', sans-serif;
        border-radius: 8px;
        display: inline-block;
        font-family: inherit;
        font-weight: 700;
        letter-spacing: 0.02rem;
        color: #fff;
        white-space: pre;
        text-align: center;

        @media (max-width: 600px) {
            padding: 0rem;
            height: 50%;
        }
    }
`;

const disabled = css`
    pointer-events: none;
    background-color: #f4f5fb;
    color: #ced1dd;
`;

type ButtonProps = {
    text?: string;
    img?: React.ReactElement;
    link?: string;
    onClick: () => void;
    ariaLabel: string;
    overrideButtonStyles: string;
    isDisabled?: boolean;
};

const Button: FC<ButtonProps> = ({
    text = 'CONTINUE',
    img = '',
    link = 'https://www.mapquest.com',
    onClick,
    ariaLabel = 'CONTINUE',
    overrideButtonStyles,
    isDisabled = false
}) => {
    const clickHandler = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        onClick();
    };

    return (
        <a
            css={
                isDisabled
                    ? css`
                          ${buttonStyles};
                          ${disabled};
                          ${overrideButtonStyles};
                      `
                    : css`
                          ${buttonStyles};
                          ${overrideButtonStyles};
                      `
            }
            href={link}
            aria-label={ariaLabel}
            rel="noopener noreferrer"
            className="conversion-button"
            onClick={clickHandler}
        >
            <span className="continue">{text}</span>
            {img}
        </a>
    );
};

export default Button;
