/* @noflow */
import React from 'react';
import PropTypes from 'prop-types';
import {css} from '@emotion/core';

const Modal = ({
    children,
    maxWidth,
    marginTop,
    innerMargin,
    height = '100%',
    backgroundColor = 'inherit',
    padding = '2rem 3rem 1rem',
    mobileHeight = '100%'
}) => {
    return (
        <aside
            className="modal"
            css={css`
                background-color: ${backgroundColor};
                position: relative;
                width: 100%;
                height: ${height};
                text-align: center;
                padding: 1rem;
                margin: ${marginTop} auto 0px auto;
                @media (max-width: 600px) {
                    padding-bottom: 5rem;
                    overflow-y: hidden;
                }
                @media (max-height: 800px) {
                    height: 100%;
                }
                @media only screen and (max-width: 600px) {
                    margin: -15px auto 0px;
                    height: ${mobileHeight};
                }
            `}
        >
            <div
                css={css`
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: ${padding};
                    margin: ${innerMargin} auto;
                    background: #fff;
                    max-width: ${maxWidth};
                    width: 100%;
                    border-radius: 8px;
                    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.11);
                    @media (max-height: 800px) {
                        margin: 5% auto;
                    }
                `}
            >
                {children}
            </div>
        </aside>
    );
};

Modal.propTypes = {
    children: PropTypes.node.isRequired,
    maxWidth: PropTypes.string,
    marginTop: PropTypes.string,
    innerMargin: PropTypes.string,
    height: PropTypes.string,
    backgroundColor: PropTypes.string,
    padding: PropTypes.string
};

Modal.defaultProps = {
    maxWidth: '650',
    marginTop: '50',
    innerMargin: '0',
    height: '100%',
    backgroundColor: 'inherit',
    padding: '2rem 3rem 1rem'
};

export default Modal;
